import React from "react";
import AttemptsWithCommands from "./AttemptsWithCommands";

function LLMCalls({ agent, onAgentClick }) {
  return (
    <>
      <h2>LLM Calls</h2>
      <div className="llm-calls-container">
        <AttemptsWithCommands className="AttemptsWithCommands" agent={agent} />
      </div>
    </>
  );
}

export default LLMCalls;
