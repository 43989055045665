import React, { useEffect, useState } from "react";
import axios from "axios";
import "../App.css";

function Agents({ run, onAgentClick, selectedAgent }) {
  const [agents, setAgents] = useState([]);
  const [showAgentData, setShowAgentData] = useState(true);

  useEffect(() => {
    let intervalId = null;

    const fetchAgents = () => {
      if (run) {
        axios
          .get(`${process.env.REACT_APP_API_URL}/agents/${run.id}`)
          .then((response) => {
            const newAgents = response.data;

            setAgents(newAgents);
            setShowAgentData(true);
          })
          .catch((error) => {
            console.error("Error fetching agents", error);
          });
      } else {
        setAgents([]);
        setShowAgentData(false);
      }
    };

    fetchAgents(); // Immediately invoke the function

    intervalId = setInterval(fetchAgents, 5000); // Fetch every 5 seconds

    return () => {
      clearInterval(intervalId); // Clear the interval when the component is unmounted or the dependencies change
    };
  }, [run, run?.id]);

  return (
    <div className="Agents">
      <h2>Agents</h2>
      <div className="agents-container">
        {showAgentData &&
          agents.map((agent) => {
            // Clean agent name
            let cleanedName = agent.agent_name
              ? agent.agent_name
                  .replace(/_/g, " ") // Replace underscores with spaces
                  .split(" ") // Split by spaces
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                  .join(" ") // Join back together
              : "Unknown Agent"; // Fallback name if agent.name is undefined

            return (
              <button
                key={agent.id}
                className={`agent ${
                  selectedAgent?.id === agent.id ? "selected" : ""
                }`}
                onClick={() => onAgentClick(agent)}
              >
                <strong>{cleanedName}</strong>
              </button>
            );
          })}
      </div>
    </div>
  );
}

export default Agents;
