import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import "../App.css";
import Select from "react-select";

function Runs({ onRunClick, selectedRun, page, setPage }) {
  const [runs, setRuns] = useState([]);
  const [maxPage, setMaxPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  const runsPerPage = 10;

  const loadData = useCallback(() => {
    setLoading(true);

    let url =
      process.env.REACT_APP_API_URL +
      `/filter/runs?page=${page}&limit=${runsPerPage}&${new URLSearchParams(
        Object.entries(filters).reduce(
          (params, [column, values]) => ({
            ...params,
            [column]: values.join(","),
          }),
          {}
        )
      ).toString()}`;

    if (searchQuery) {
      url += `&search=${searchQuery}`; // Include search query in the URL
    }

    axios
      .get(url)
      .then((response) => {
        const runsData = response.data.data;
        const totalCostPromises = runsData.map((run) =>
          axios
            .get(`${process.env.REACT_APP_API_URL}/total_cost/${run.id}`)
            .then((res) => ({ ...run, total_cost: res.data.total_cost }))
        );

        Promise.all(totalCostPromises).then((runsWithCost) => {
          setRuns(runsWithCost);
          setMaxPage(Math.ceil(response.data.total / runsPerPage));
          setLoading(false);
        });
      })
      .catch((error) => {
        console.error("Error fetching runs", error);
        setLoading(false);
      });
  }, [page, filters, searchQuery]); // Add searchQuery to dependencies

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleFilterChange = (column) => (selectedOptions) => {
    const value = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilters((prev) => ({ ...prev, [column]: value }));
  };

  const handleNextClick = () => {
    if (page < maxPage) setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page > 1) setPage(page - 1);
  };

  const fetchFilterOptions = useCallback(() => {
    const fetchOptions = (column) =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/filter/${column}`)
        .then((response) => response.data)
        .catch((error) => {
          console.error(`Error fetching ${column}`, error);
        });

    Promise.all(
      ["customer_name", "workflow_name", "status"].map(fetchOptions)
    ).then((values) => {
      setFilterOptions({
        customer_name: values[0],
        workflow_name: values[1],
        status: values[2],
      });
    });
  }, []);

  useEffect(() => {
    fetchFilterOptions();
  }, [fetchFilterOptions]);

  const getDisplayName = (column) => {
    return column
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fetchTotalCost = useCallback((workflowInstanceId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/total_cost/${workflowInstanceId}`)
      .catch((error) => {
        console.error("Error fetching total cost", error);
      });
  }, []);

  useEffect(() => {
    if (selectedRun) {
      fetchTotalCost(selectedRun.id);
    }
  }, [selectedRun, fetchTotalCost]);

  return (
    <div className="Runs">
      <h2>Runs</h2>
      <div className="controls-container">
        <button
          className="refresh-button"
          onClick={() => {
            loadData();
            fetchFilterOptions();
          }}
        >
          Refresh
        </button>
        <input
          type="text"
          className="search-input"
          placeholder="Search by Workflow ID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
        />
        {["customer_name", "workflow_name", "status"].map((column) => (
          <Select
            key={column}
            isMulti
            placeholder={`Select ${getDisplayName(column)}...`}
            value={filters[column]?.map((value) => ({ label: value, value }))}
            onChange={handleFilterChange(column)}
            options={filterOptions[column]?.map((value) => ({
              label: value,
              value,
            }))}
          />
        ))}
        <button
          className="page-button"
          onClick={handlePrevClick}
          disabled={page === 1}
        >
          Previous
        </button>
        <button
          className="page-button"
          onClick={handleNextClick}
          disabled={page === maxPage}
        >
          Next
        </button>
      </div>
      <div className="runs-container">
        {runs.map((run) => (
          <button
            key={run.id}
            className={`run ${selectedRun?.id === run.id ? "selected" : ""} ${
              run.status ? "run-" + run.status.replace(" ", "-") : ""
            }`}
            onClick={() => onRunClick(run)}
          >
            <strong>Workflow ID:</strong> {run.id}
            <br /> <br />
            <strong>Customer Name:</strong> {run.customer_name} <br />
            <strong>Workflow:</strong> {run.workflow_name} <br />
            <strong>Started At:</strong>{" "}
            {new Date(run.created_at).toLocaleString()} <br />
            <strong>Ended At:</strong>{" "}
            {run.end_time ? new Date(run.end_time).toLocaleString() : "N/A"}
            <br />
            <strong>Total Cost:</strong> $
            {Number(run.total_cost)
              .toFixed(6)
              .replace(/\.?0+$/, "")}
          </button>
        ))}
        {loading && <p>Loading...</p>}
      </div>
    </div>
  );
}

export default Runs;
