import React, { useState } from "react";
import axios from "axios";
import "../App.css";

function LoginForm({ onLogin }) {
  const [loginFailed, setLoginFailed] = useState(false);
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const username = "admin";

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/filter/runs?page=1&limit=10`,
        {
          headers: {
            Authorization: `Basic ${btoa(`${username}:${password}`)}`,
          },
        }
      );

      // If successful, set the default headers for axios
      axios.defaults.headers.common["Authorization"] = `Basic ${btoa(
        `${username}:${password}`
      )}`;

      if (response.status === 200) {
        setLoginFailed(false);
        onLogin();
      }
      setLoginFailed(true);
    } catch (error) {
      // Show an error message
      console.log("Login failed");
      setLoginFailed(true);
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <label>
        <span>
          <strong>Password</strong>
        </span>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </label>
      <button type="submit">
        <strong>Log in</strong>
      </button>
      {loginFailed && (
        <p className="error-message">ah ah ah you didn't say the magic word</p>
      )}
    </form>
  );
}

export default LoginForm;
