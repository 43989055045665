import React, { useState, useEffect } from "react";
import Runs from "./components/Runs";
import Agents from "./components/Agents";
import LLMCalls from "./components/LlmCalls";
import LoginForm from "./components/Login";
import "./App.css";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedRun, setSelectedRun] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setSelectedAgent(null);
    setSelectedRun(null); // Reset selectedRun as well on page change
  }, [page]);

  const handleRunClick = (run) => {
    if (!run) {
      console.error("Selected run is undefined");
      return;
    }
    setSelectedRun(run);
    setSelectedAgent(null);
  };

  const handleAgentClick = (agent) => {
    setSelectedAgent(agent);
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <div className="app-container">
          <>
            <Runs
              onRunClick={handleRunClick}
              page={page}
              setPage={setPage}
              selectedRun={selectedRun}
            />

            {selectedRun ? (
              <Agents
                run={selectedRun}
                onAgentClick={handleAgentClick}
                selectedAgent={selectedAgent}
              />
            ) : (
              <div className="Agents">
                <div className="agents-placeholder">
                  Select a run to see agents
                </div>
              </div>
            )}
            <div className="LLMCalls">
              {selectedAgent ? (
                <LLMCalls
                  agent={selectedAgent}
                  onAgentClick={handleAgentClick}
                />
              ) : (
                <div className="llm-calls-placeholder">
                  Select an agent to see LLM calls
                </div>
              )}
            </div>
          </>
        </div>
      ) : (
        <div className="form-wrapper">
          <LoginForm onLogin={handleLogin} />
        </div>
      )}
    </div>
  );
}

export default App;
