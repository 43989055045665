import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import _ from "lodash";
import { JSONTree } from "react-json-tree";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/themes/prism.css";

function AttemptsWithCommands({ agent = null }) {
  const [llmCalls, setLlmCalls] = useState([]);
  const [fetchedLlmCalls, setFetchedLlmCalls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [showFullPrompt, setShowFullPrompt] = useState(false);
  const popupRef = useRef(null);

  const fetchLlmCalls = () => {
    const endpoint = getEndpoint();

    if (!endpoint) {
      setFetchedLlmCalls([]);
      setHasFetched(false);
      return;
    }

    axios
      .get(endpoint)
      .then((response) => {
        const newLlmCalls = response.data;
        if (newLlmCalls.length === 0) {
          setFetchedLlmCalls([]);
        } else {
          const actionsPromises = newLlmCalls.map((llmCall) =>
            axios.get(
              `${process.env.REACT_APP_API_URL}/actions/${llmCall.agent_instance_id}`
            )
          );
          Promise.all(actionsPromises).then((actionsResponses) => {
            const llmCallsWithActions = newLlmCalls.map((llmCall, index) => {
              const actions = actionsResponses[index].data;
              return {
                ...llmCall,
                actions,
              };
            });
            setFetchedLlmCalls(llmCallsWithActions);
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching llm calls or actions", error);
        setError("Error fetching llm calls or actions");
        setIsLoading(false);
      });
  };

  const formatCommand = (command) => {
    let formattedCommand = "";
    let commandObj = {};

    try {
      commandObj = JSON.parse(command);
      let text = commandObj.args.text || commandObj.args.write_string || "";
      let language =
        commandObj.name === "write_to_file" ? "python" : "javascript";
      formattedCommand = Prism.highlight(
        text,
        Prism.languages[language],
        language
      );
      delete commandObj.args.text;
      delete commandObj.args.write_string;
    } catch (e) {
      formattedCommand = command;
    }

    let remainingCommand = Prism.highlight(
      JSON.stringify(commandObj, null, 2),
      Prism.languages.json,
      "json"
    );

    return {
      formattedCommand,
      remainingCommand,
    };
  };

  const renderCommand = (command) => {
    let { formattedCommand, remainingCommand } = formatCommand(command.command);
    return (
      <div>
        {remainingCommand && (
          <pre
            style={{
              whiteSpace: "pre-wrap",
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            <code dangerouslySetInnerHTML={{ __html: remainingCommand }} />
          </pre>
        )}
        {formattedCommand && (
          <pre
            style={{
              whiteSpace: "pre-wrap",
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            <code dangerouslySetInnerHTML={{ __html: formattedCommand }} />
          </pre>
        )}
      </div>
    );
  };

  const formatCompletion = (completion) => {
    let completionObj = {};
    try {
      completionObj = JSON.parse(completion);
      for (let key in completionObj) {
        try {
          completionObj[key] = JSON.parse(completionObj[key]);
        } catch (e) {
          // not a JSON string, leave it as is
        }
      }
      delete completionObj.text;
      delete completionObj.write_string;
      delete completionObj.commands;
    } catch (e) {
      completionObj = completion;
    }

    let completionText = Prism.highlight(
      JSON.stringify(completionObj, null, 2),
      Prism.languages.json,
      "json"
    );

    return completionText;
  };

  useEffect(() => {
    let intervalId = null;

    fetchLlmCalls();

    intervalId = setInterval(fetchLlmCalls, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [agent]);

  const getEndpoint = () => {
    if (agent) {
      return `${process.env.REACT_APP_API_URL}/llm_calls/${agent.id}`;
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowFullPrompt(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!_.isEqual(fetchedLlmCalls, llmCalls)) {
      setIsLoading(true);
      setLlmCalls(fetchedLlmCalls);
      setIsLoading(false);
      setHasFetched(true);
    }
  }, [llmCalls, fetchedLlmCalls]);

  const toggleFullPrompt = (event, prompt) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    setSelectedPrompt(prompt);
    setShowFullPrompt(!showFullPrompt);
  };

  if (isLoading || (!hasFetched && agent)) {
    return <div className="AttemptsWithCommands">Loading...</div>;
  }

  if (error) {
    return <div className="AttemptsWithCommands">{error}</div>;
  }

  const theme = {
    base00: "#f7f7f7" /* background color */,
    base03: "#f7f7f7" /* item number color */,
    base0B: "#2b2b2b" /* text color */,
    base0D: "#247BA0" /* key color */,
  };

  return (
    <div className="AttemptsWithCommands">
      {llmCalls.map((llmCall) => (
        <div key={llmCall.id}>
          <button
            className={`attempt attempt-${llmCall.status}`}
            onClick={(event) => toggleFullPrompt(event, llmCall.input)}
          >
            <strong>LLM Call ID: </strong> {llmCall.id}
            <br />
            <strong>Date:</strong>{" "}
            {new Date(llmCall.created_at).toLocaleString()}
            <br />
            <strong>Model Name:</strong> {llmCall.model_name}
            <br />
            <strong>Temperature:</strong> {llmCall.temperature}
            <br />
            <strong>Input Tokens:</strong> {llmCall.input_tokens}
            <br />
            <strong>Completion Tokens:</strong> {llmCall.completion_tokens}
            <br />
            <strong>Cost: </strong>$
            {Number(llmCall.cost)
              .toFixed(6)
              .replace(/\.?0+$/, "")}
            <br />
            <br />
            <strong>Completion:</strong>
            <br />
            <br />
            <pre
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid black",
                backgroundColor: "white",
              }}
            >
              <code
                dangerouslySetInnerHTML={{
                  __html: formatCompletion(llmCall.completion),
                }}
              />
            </pre>{" "}
            {llmCall.actions
              .filter((action) => action.llm_call_id === llmCall.id) // Filter actions by llm_call_id
              .map((action) => (
                <div key={action.id}>
                  <strong>Action:</strong>
                  <br />
                  <br />
                  <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      border: "1px solid black",
                      backgroundColor: "white",
                    }}
                  >
                    <code
                      dangerouslySetInnerHTML={{
                        __html: Prism.highlight(
                          JSON.stringify(action, null, 2),
                          Prism.languages.json,
                          "json"
                        ),
                      }}
                    />
                  </pre>
                  <strong>Inputs:</strong>
                  <br />
                  {action.inputs.map((input) => (
                    <div key={input.id}>
                      <strong>{input.name}:</strong> {input.value}
                    </div>
                  ))}
                  <br />
                  <strong>Output:</strong>
                  <p>{action.output}</p>
                </div>
              ))}
          </button>
          {selectedPrompt === llmCall.input && showFullPrompt && (
            <div
              className="popup"
              ref={popupRef}
              onClick={(event) => toggleFullPrompt(event, llmCall.input)}
            >
              <div
                className="popup-content"
                onClick={(event) => event.stopPropagation()}
              >
                <h3>Full Prompt:</h3>
                <JSONTree
                  data={JSON.parse(llmCall.input)}
                  theme={theme}
                  invertTheme={false}
                  shouldExpandNodeInitially={() => true}
                />
                <button
                  className="close-button"
                  onClick={() => toggleFullPrompt(llmCall.input)}
                >
                  <strong>Close</strong>
                </button>
              </div>
            </div>
          )}{" "}
        </div>
      ))}
    </div>
  );
}

export default AttemptsWithCommands;
